import React from 'react'
import './privacypolicy.css';
import { Navbar } from '../../components/navbar/Navbar'
import Footer from '../../components/footer/Footer'
import { FaPhone, FaEnvelope } from 'react-icons/fa6';

const PrivacyPolicy = () => {
    return (
        <div className="privacy-policy">
            <Navbar />
            <div className='privacy-policy-header'>
                <h1>Privacy Policy</h1>
                <h5>Home &gt; <span>Privacy Policy</span></h5>
            </div>

            <div className='privacy-policy-cont'>
                <section className="privacy-policy-content">
                    <article>
                        <p>
                            <strong>Shree Kishori Priya Foundation</strong> ("we," "our," or "us") operates the website
                            <a href="https://shreekishoripriya.org" target="_blank" rel="noopener noreferrer"> shreekishoripriya.org</a>,
                            dedicated to supporting elderly women through donations for food, healthcare, and festivals. We also provide blogs, videos,
                            and a YouTube channel to spread awareness about our mission.
                        </p>
                    </article>

                    <article>
                        <h2>1. Information We Collect</h2>
                        <ul>
                            <li><strong>Personal Information:</strong> When you make a donation, subscribe to our newsletter, or contact us, we may collect your name, email, phone number, and payment details.</li>
                            {/* <li><strong>Non-Personal Information:</strong> We collect data such as IP addresses, browser type, and device information to improve user experience.</li> */}
                            {/* <li><strong>Cookies and Tracking Technologies:</strong> We use cookies to enhance website functionality and track visitor engagement.</li> */}
                        </ul>
                    </article>

                    <article>
                        <h2>2. How We Use Your Information</h2>
                        <ul>
                            <li>Processing donations and providing receipts.</li>
                            <li>Sending updates about our NGO and donation campaigns.</li>
                            <li>Improving website functionality and content.</li>
                            <li>Responding to inquiries and user feedback.</li>
                        </ul>
                    </article>

                    <article>
                        <h2>3. How We Share Your Information</h2>
                        <p>We do not sell or rent your personal data. However, we may share information with:</p>
                        <ul>
                            <li><strong>Payment Processors:</strong> To securely process payments.</li>
                            <li><strong>Third-Party Services:</strong> For analytics, website hosting, and email communication.</li>
                            <li><strong>Legal Authorities:</strong> If required by law or to protect our website and users.</li>
                        </ul>
                    </article>

                    <article>
                        <h2>4. Data Security</h2>
                        <p>We implement security measures to protect your information. However, no data transmission over the internet is 100% secure.</p>
                    </article>

                    <article>
                        <h2>5. Third-Party Links</h2>
                        <p>Our website contains links to external websites, including YouTube. We are not responsible for their privacy practices.</p>
                    </article>

                    <article>
                        <h2>6. Your Rights</h2>
                        <ul>
                            <li>Access, update, or delete your personal data.</li>
                            <li>Opt out of email communications.</li>
                            <li>Disable cookies through your browser settings.</li>
                        </ul>
                    </article>

                    <article>
                        <h2>7. Updates to This Policy</h2>
                        <p>We may update this privacy policy periodically. Changes will be posted on this page.</p>
                    </article>

                    <article>
                        <h2>8. Contact Us</h2>
                        <p>If you have any questions regarding this policy, please contact us at:</p>
                        <address>
                            <p><FaEnvelope /> Email: <a href="mailto:shreekishoripriya@gmail.com">shreekishoripriya@gmail.com</a></p>
                            <p><FaPhone />  Phone: <a href="tel:+918979545845">+91-8979545845</a></p>
                        </address>
                    </article>
                </section>
            </div>

            <div className="bottom-privacy-policy">
                <h6>The beauty of life does not depend on how happy you are, but on <span>how happy</span> others can be <span>because of you</span></h6>
            </div>
            <Footer />
        </div>
    )
}

export default PrivacyPolicy