import React from 'react'
import './termsandconditions.css';
import { Navbar } from '../../components/navbar/Navbar'
import Footer from '../../components/footer/Footer'

const TermsAndConditions = () => {
  return (
    <div className="tandc">
      <Navbar />
      <div className='tandc-header'>
        <h1>Terms & Conditions</h1>
        <h5>Home &gt; <span>Terms & Conditions</span></h5>
        {/* <section class="tandc-header">
            <h1>Terms & Conditions</h1>
            <nav aria-label="breadcrumb">
                <ul>
                    <li><a href="/">Home</a></li>
                    <li>Terms & Conditions</li>
                </ul>
            </nav>
        </section> */}
      </div>

      <div className='tandc-cont'>
        <section className="tandc-content">
          <article>
            {/* <h2>Effective Date: [Insert Date]</h2> */}
            <p>Welcome to Shree Kishori Priya Foundation (<a href="https://shreekishoripriya.org" target="_blank" rel="noopener noreferrer">shreekishoripriya.org</a>). These Terms and Conditions ("Terms") govern your use of our website and services. By accessing or using our website, you agree to comply with these Terms.</p>
          </article>

          <article>
            <h2>1. Use of Our Website</h2>
            <p>You must use this website for lawful purposes only. It is intended to facilitate donations for elderly women's food, healthcare, and festivals, as well as to provide blogs, videos, and other informational content. Fraudulent, abusive, or harmful activities are strictly prohibited.</p>
          </article>

          <article>
            <h2>2. Donations</h2>
            <p>All donations are voluntary and used solely for charitable purposes. By donating, you confirm that the funds belong to you and are given without any expectation of return. Refunds are subject to our Refund Policy.</p>
          </article>

          <article>
            <h2>3. Content Ownership and Usage</h2>
            <p>The content on this website, including text, images, blogs, and videos, is owned by Shree Kishori Priya Foundation unless otherwise stated. Unauthorized reproduction, distribution, or use of our content is prohibited.</p>
          </article>

          <article>
            <h2>4. User Conduct</h2>
            <p>Users must not post or share harmful, misleading, or offensive content. Any attempt to disrupt the website’s operations (e.g., hacking, spreading malware) is strictly prohibited.</p>
          </article>

          <article>
            <h2>5. Privacy Policy</h2>
            <p>Your use of this website is also governed by our <a href="/privacy-policy">Privacy Policy</a>, which outlines how we collect and use personal data.</p>
          </article>

          <article>
            <h2>6. Limitation of Liability</h2>
            <p>We strive to provide accurate and up-to-date information, but we do not guarantee its completeness or accuracy. We are not liable for any losses or damages arising from the use of this website.</p>
          </article>

          <article>
            <h2>7. Modifications to Terms</h2>
            <p>We reserve the right to update these Terms at any time. Changes will be posted on this page, and continued use of the website constitutes acceptance of the updated Terms.</p>
          </article>

          <article>
            <h2>8. Contact Us</h2>
            <p>For any questions regarding these Terms, please contact us:</p>
            <address>
              <p>Email: <a href="mailto:shreekishoripriya@gmail.com">shreekishoripriya@gmail.com</a></p>
              <p>Phone: <a href="tel:+918979545845">+91-8979545845</a></p>
            </address>
          </article>
        </section>
      </div>

      <div className="bottom-tandc">
        <blockquote>
          The beauty of life does not depend on how happy you are, but on <span>how happy</span> others can be <span>because of you</span>
        </blockquote>
      </div>
      <Footer />
    </div>
  )
}

export default TermsAndConditions