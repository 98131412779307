import React, { useState } from "react";
import "./navbar.css";
import { Link, useNavigate } from "react-router-dom";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { FaBars } from "react-icons/fa6";

export const Navbar = () => {

    const navigate = useNavigate();

    const [isModalOpen, setModalOpen] = useState(false);

    const handleFabarClick = () => {
        setModalOpen(true);
    };

    // const handleContactClick = () => {
    //     // Navigate to the home page
    //     navigate('/');

    //     // Scroll to the contact component
    //     const contactComponent = document.getElementById('contact');
    //     contactComponent.scrollIntoView({ behavior: "smooth" });
    // };
    const handleContactClick = () => {
        // Scroll to the contact component on the home page
        const contactComponent = document.getElementById('contact');
        if (contactComponent) {
            setModalOpen(false)
            contactComponent.scrollIntoView({ behavior: "smooth" });
        } else {
            navigate('/'); // Navigate to home page if contact component is not found
        }
    };

    const handleCampignsClick = () => {
        // Scroll to the campaigns component on the home page
        const campaignsComponent = document.getElementById('campaigns');
        if (campaignsComponent) {
            setModalOpen(false)
            campaignsComponent.scrollIntoView({ behavior: "smooth" });
        } else {
            navigate('/');
        }
    };

    return (
        <div className="navbar">
            <div className="left">
                {/* <img src="/assets/logo.png" alt="" onClick={() => navigate('/')} />
                <img src="/assets/logo.webp" alt="" onClick={() => navigate('/')} /> */}
                <img src="/assets/logo_compressed.png" alt="" onClick={() => navigate('/')} />
            </div>
            <div className="right">
                <div className="nav-opt" onClick={() => navigate('/')}>Home</div>
                <div className="nav-opt" onClick={handleCampignsClick}>Campaigns</div>
                <div className="nav-opt" onClick={handleContactClick}>Contacts Us</div>
                <div className="nav-opt" onClick={() => navigate('/blog')}>Blogs</div>
                <button className="nav-btn" onClick={() => navigate('/donate')}>DONATE NOW</button>
                <button onClick={handleFabarClick} className="nav-fabar"><FaBars /></button>
                <Dialog
                    open={isModalOpen}
                    onClose={() => setModalOpen(false)}
                    fullWidth={true} // Set to true to make the dialog take up the full width
                    maxWidth="xs"
                    PaperProps={{ style: { backgroundColor: '#F6F6F6', maxWidth: '300px', borderRadius: '20px' } }}
                >
                    <DialogContent>
                        <div className="modal-content">
                            <div className="nav-btn-cont-mob">
                                <Link to="/" className='nav-link'>
                                    <button className='nav-btn-mob'>Home</button>
                                </Link>
                                <Link to="/" className='nav-link'>
                                    <button className='nav-btn-mob' onClick={handleCampignsClick}>Campaigns</button>
                                </Link>
                                <Link to="/" className='nav-link'>
                                    <button className='nav-btn-mob' onClick={handleContactClick}>Contacts Us</button>
                                </Link>
                                <Link to="/blog" className='nav-link'>
                                    <button className='nav-btn-mob'>Blogs</button>
                                </Link>
                                {/* <Link to="/" className='nav-link'> */}
                                <button className='nav-btn-mob' onClick={() => navigate('/donate')}>DONATE NOW</button>
                                {/* </Link> */}
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>

            </div>
        </div>
    );
};
