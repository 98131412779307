import React, { useEffect, useState } from "react";
import "./donate.css"
import { Navbar } from '../../components/navbar/Navbar'
import { axiosRequest } from '../../services/ApiCall';
import { SyncLoader } from "react-spinners";
import validator from "validator";
import { toast } from "react-toastify";
import Footer from "../../components/footer/Footer";

import { cashfree } from './cashfreeScript'
// import { cashfree } from './util'
// import cashfreeimg from '../../image/cashfree.png'
import { useParams } from 'react-router-dom';

import { LinearProgress } from '@mui/material';
import { ClipLoader } from "react-spinners";
import { Link } from 'react-router-dom';

const Donate = () => {

    const [nationality, setNationality] = useState("indian");
    const [amount, setAmount] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNo, setPhoneNo] = useState("");
    const [panNo, setPanNo] = useState("");
    const [address, setAddress] = useState("");
    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [pinCode, setPinCode] = useState("");
    const [campaigns, setCampaigns] = useState([]);
    const [selectedCampaign, setSelectedCampaign] = useState("");
    const [isLoading, setIsLoading] = useState(false); // State variable to track loading state
    const [minDonation, setMinDonation] = useState(0);
    const [activeTab, setActiveTab] = useState('qr');

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    useEffect(() => {
        // Fetch the campaigns from the backend
        const fetchCampaigns = async () => {
            try {
                const { data } = await axiosRequest.get("/campaign/get");
                setCampaigns(data.campaign);
            } catch (error) {
                console.error("Error fetching campaigns:", error);
            }
        };

        fetchCampaigns();
    }, []);


    //   useEffect(() => {
    //     const script = document.createElement("script");
    //     script.src = "https://sdk.cashfree.com/checkout.js";
    //     script.async = true;
    //     document.body.appendChild(script);

    //     return () => {
    //       document.body.removeChild(script); // Cleanup script on unmount
    //     };
    //   }, []);


    // const handleChangeAmount = (e) => {
    //     if (!isNaN(e.target.value)) {
    //         setAmount(e.target.value);
    //     }
    // };

    // const handleChangeCampaign = (e) => {
    //     setSelectedCampaign(e.target.value);
    // };

    const handleChangeAmount = (e) => {
        const value = e.target.value;
        if (!isNaN(value) && value >= minDonation) {
            setAmount(value);
        } else if (value === "") {
            setAmount("");
        }
    };

    // const handleChangeAmount = (e) => {
    //     const value = parseFloat(e.target.value);
    //     if (!isNaN(value)) {
    //         if (value >= minDonation) {
    //             setAmount(value);
    //         } else if (value === "") {
    //             setAmount("");
    //         } else {
    //             // Optionally show an error message
    //             toast.error(`Amount cannot be less than ${minDonation}`);
    //         }
    //     }
    // };

    const handleChangeCampaign = (e) => {
        const selected = campaigns.find(campaign => campaign.campaignName === e.target.value);
        // setSelectedCampaign(selected.campaignName);
        setSelectedCampaign(selected._id);
        setMinDonation(selected.minDonation || 0); // Set minimum donation amount if it exists
        setAmount(selected.minDonation || ""); // Update the amount field with minimum donation amount
    };

    const params = useParams()
    const isSessionId = params.sessionid
    const [loading, setLoading] = useState('');
    const [sessionId, setSessionId] = useState('');
    let version = cashfree.version();

    // const getSessionId = (e) => {
    //     e.preventDefault();
    //     setLoading(true);
    //     axiosRequest.post('/checkout', { version })
    //         .then((res) => {
    //             setLoading(false);
    //             setSessionId(res.data);
    //             // window.open(res.data, '_blank');
    //         })
    //         .catch((err) => {
    //             setLoading(false);
    //             console.log(err);
    //         })
    // }

    // const handlePayment = () => {
    //     let checkoutOptions = {
    //         paymentSessionId: sessionId,
    //         returnUrl: "http://localhost:8080/api/status/{order_id} ",

    //     }
    //     cashfree.checkout(checkoutOptions).then(function (result) {
    //         if (result.error) {
    //             alert(result.error.message);
    //         }
    //         if (result.redirect) {
    //             console.log("Redirection")
    //             console.log(result);
    //         }
    //     });
    // }

    // useEffect(() => {
    //     setSessionId(isSessionId)
    // }, [isSessionId])

    //     const params = useParams();
    //   const isSessionId = params.sessionid;
    //   const [loading, setLoading] = useState(false);
    //   const [sessionId, setSessionId] = useState('');
    //   const [cfVersion, setCfVersion] = useState(null);

    //   // Poll for window.cashfree until it's available
    //   useEffect(() => {
    //     const interval = setInterval(() => {
    //       if (window.cashfree && window.cashfree.version) {
    //         setCfVersion(window.cashfree.version());
    //         clearInterval(interval);
    //       }
    //     }, 100);
    //     return () => clearInterval(interval);
    //   }, []);

    //   const getSessionId = (e) => {
    //     e.preventDefault();
    //     // if (!cfVersion) {
    //     //   console.error("Cashfree SDK not loaded yet");
    //     //   return;
    //     // }
    //     setLoading(true);
    //     axiosRequest.post('/checkout', { version: cfVersion })
    //       .then((res) => {
    //         setLoading(false);
    //         setSessionId(res.data);
    //       })
    //       .catch((err) => {
    //         setLoading(false);
    //         console.log(err);
    //       });
    //   };

    //   const handlePayment = () => {
    //     if (!sessionId) {
    //       alert("Session ID is not available");
    //       return;
    //     }
    //     let checkoutOptions = {
    //       paymentSessionId: sessionId,
    //       returnUrl: "http://localhost:8080/api/status/{order_id}",
    //     };
    //     if (window.cashfree) {
    //       window.cashfree.checkout(checkoutOptions).then(function (result) {
    //         if (result.error) {
    //           alert(result.error.message);
    //         }
    //         if (result.redirect) {
    //           console.log("Redirection", result);
    //         }
    //       });
    //     } else {
    //       alert("Cashfree SDK not loaded");
    //     }
    //   };

    //   useEffect(() => {
    //     setSessionId(isSessionId);
    //   }, [isSessionId]);

    const handleIntiatePayment = async () => {
        try {
            // if (!name || !phoneNo || !email || !amount || !selectedCampaign || !address || !state || !city || !pinCode || !nationality) {
            //     toast.error("All fields are required");
            // }
            if (!selectedCampaign?.trim()) {
                toast.error("Please select a campaign");
            } else if (!phoneNo || !validator.isMobilePhone(phoneNo, 'en-IN')) {
                toast.error('Please enter a valid Phone Number');
            }
            // else if (!email || !validator.isEmail(email)) {
            //     toast.error("Please enter a valid Email");
            // } 
            else if (panNo && !validator.matches(panNo, /^[A-Z]{5}[0-9]{4}[A-Z]$/)) {
                toast.error("Invalid PAN Number");
            } else if (!amount || isNaN(amount) || parseFloat(amount) < minDonation) {
                toast.error(`Amount cannot be less than ${minDonation}`);
            } else {

                setIsLoading(true);

                // axiosRequest.post('/checkout', { version })
                axiosRequest.post(`/checkout?name=${name}&email=${email}&phoneNo=${phoneNo}&amount=${amount}&panNo=${panNo}&campaign=${selectedCampaign}&address=${address}&state=${state}&city=${city}&pinCode=${pinCode}&nationality=${nationality}`, { version })
                    .then((res) => {
                        setLoading(false);
                        setSessionId(res.data);
                        console.log('res.data', res.data);
                        if (res.data) {
                            let checkoutOptions = {
                                paymentSessionId: res.data,
                                // returnUrl: "http://localhost:8080/api/v1/paymentverification/{order_id}",
                                // returnUrl: "https://test.cashfree.com/pgappsdemos/return.php?order_id=order_123",
                                returnUrl: "https://backendbackup-qw5ft.ondigitalocean.app/api/v1/paymentverification/{order_id}",
                                // returnUrl: `http://localhost:8080/api/v1/paymentverification?order_id=${order_id}`,
                            }
                            cashfree.checkout(checkoutOptions).then(function (result) {
                                if (result.error) {
                                    toast.error(result || result.error || result.error.message || "Payment failed! Please try again.");
                                }
                                if (result.redirect) {
                                    // console.log("Redirection")
                                    // console.log(result);
                                }
                            });
                            // handlePayment()
                        }
                        // window.open(res.data, '_blank');
                    })
                    .catch((error) => {
                        setLoading(false);
                        const errorMessage = error || error.response?.data?.message || "Payment initiation failed! Please try again later.";
                        toast.error(errorMessage);
                        // console.log(error);
                    })
            }
        } catch (error) {
            console.error("Error during Cashfree checkout:", error);
            toast.error("Payment initiation failed! Please try again later.");
        } finally {
            setIsLoading(false);
        }
    };

    // Render loading indicator when isLoading is true
    if (isLoading) {
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                backgroundColor: "#F6F6F6"
            }}>
                <SyncLoader color="#4496D2" />
            </div>
        );
    }

    return (
        <div className='donate'>
            <Navbar />
            <div className='donate-header'>
                <h1>Donate Now</h1>
                <h5>Home &gt; <span>Donate</span></h5>
            </div>

            {/* <div className='card px-5 py-4 mt-5'>
                <form onSubmit={getSessionId}>
                    <h1>Session Id</h1>
                    <input type="text" value={sessionId} onChange={(e) => { setSessionId(e.target.value) }} />
                    {!loading ? <div className='col-12 center'>
                        <button className='w-100 ' type="submit">getSessionID</button>
                    </div>
                        :
                        <div className='col-12 center'>
                            <button className='w-100 text-center' type="submit">
                                <div className="spinner-border" role="status">
                                    <span className="visually-hidden ">Wait...</span>
                                </div>
                            </button>
                        </div>
                    }
                </form>
                <div className='col-12 center'>
                    <button className='w-100 ' type="submit" onClick={handlePayment}>Pay Now</button>
                </div>
            </div> */}

            <div className="donate-cont">
                <div className="donate-card">
                    {/* <h2>Yes I’d like to help</h2> */}

                    <div className='donate-payment-tab-btns'>
                        <button
                            className={`donate-tab-btn ${(activeTab) === 'qr' ? 'active' : ''}`}
                            onClick={() => handleTabClick('qr')}
                        >
                            QR-Scan & Pay
                        </button>
                        <button
                            className={`donate-tab-btn ${(activeTab) === 'campaign_donation' ? 'active' : ''}`}
                            onClick={() => handleTabClick('campaign_donation')}
                        >
                            Campaign Donation
                        </button>
                    </div>

                    {activeTab === 'qr' && (
                        <div className="donate-payment-tab-cont">
                            {/* <h1 className="donate-payment-tab-cont-head">Scan & Pay as Anonymous</h1> */}
                            <h1 className="donate-payment-tab-cont-head">माताओं की अपनी इच्छा अनुसार सेवा करें।</h1>
                            {/* <img src="/assets/kishori_priya_qrcode.jpeg" alt="" /> */}
                            <div className="dp-qr-code-cont">
                                <img src="/assets/skp_qrcode.jpeg" alt="" />
                                <span className="dp-upi-id">UPI ID - 66719301@ubin</span>
                            </div>
                        </div>
                    )}

                    {activeTab === 'campaign_donation' && (
                        <div className="donate-payment-tab-cont">
                            <h1 className="donate-payment-tab-cont-head">Yes I’d like to help</h1>
                            <label htmlFor="nationality" className="donate-field-label">
                                Nationality
                                <div>
                                    <input
                                        type="radio"
                                        name="nationality"
                                        id="indian"
                                        value="indian"
                                        checked={nationality === "indian"}
                                        onChange={(e) => setNationality(e.target.value)}
                                    />
                                    Indian
                                    <input
                                        type="radio"
                                        name="nationality"
                                        id="anonymous"
                                        value="anonymous"
                                        checked={nationality === "anonymous"}
                                        onChange={(e) => setNationality(e.target.value)}
                                    />
                                    Pay as anonymous
                                </div>
                            </label>
                            <label htmlFor="campaign" className="donate-field-label">Campaign
                                <select
                                    value={selectedCampaign}
                                    onChange={handleChangeCampaign}
                                >
                                    <option value="" disabled>Select campaign</option>
                                    {campaigns?.map((campaign) => (
                                        <option key={campaign._id} value={campaign.campaignName}>
                                            {campaign?.campaignName}
                                        </option>
                                    ))}
                                </select>
                            </label>

                            <div className="donate-fields">
                                Details
                                <div className="donate-input-fields">
                                    <label htmlFor="name">Name
                                        <input
                                            type="text"
                                            id="name"
                                            value={name}
                                            placeholder="Enter your name"
                                            onChange={(e) => setName(e.target.value)}
                                        />
                                    </label>
                                    <label htmlFor="phoneNo">Phone number
                                        <input
                                            type="tel"
                                            id="phoneNo"
                                            value={phoneNo}
                                            placeholder="Enter your phone number"
                                            onChange={(e) => setPhoneNo(e.target.value)}
                                        />
                                    </label>
                                    <label htmlFor="email">Email
                                        <input
                                            type="email"
                                            id="email"
                                            value={email}
                                            placeholder="Enter your email"
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </label>
                                    <label htmlFor="panNo">Pan no (optional)
                                        <input
                                            type="text"
                                            id="panNo"
                                            value={panNo}
                                            placeholder="Enter your PAN number"
                                            onChange={(e) => setPanNo(e.target.value)}
                                        />
                                    </label>
                                    <label htmlFor="address">Address
                                        <input
                                            type="text"
                                            id="address"
                                            value={address}
                                            placeholder="Enter the address"
                                            onChange={(e) => setAddress(e.target.value)}
                                        />
                                    </label>
                                    <label htmlFor="state">State
                                        <input
                                            type="text"
                                            id="state"
                                            value={state}
                                            placeholder="Enter the state"
                                            onChange={(e) => setState(e.target.value)}
                                        />
                                    </label>
                                    <label htmlFor="city">City
                                        <input
                                            type="text"
                                            id="city"
                                            value={city}
                                            placeholder="Enter the city"
                                            onChange={(e) => setCity(e.target.value)}
                                        />
                                    </label>
                                    <label htmlFor="pin code">Pin Code
                                        <input
                                            type="text"
                                            id="pinCode"
                                            value={pinCode}
                                            placeholder="Enter the pin code"
                                            onChange={(e) => setPinCode(e.target.value)}
                                        />
                                    </label>
                                </div>

                            </div>
                            <label htmlFor="amount" className="donate-amount-label">Amount
                                <input
                                    type="text"
                                    id="amount"
                                    value={amount}
                                    placeholder="Enter the amount"
                                    // placeholder={`Enter the amount (min ${minDonation})`}
                                    onChange={handleChangeAmount}
                                    min={minDonation}
                                />
                            </label>
                            <button className="donate-btn" onClick={handleIntiatePayment} disabled={isLoading}>
                                {isLoading ? "Processing..." : "Donate"}
                            </button>
                        </div>
                    )}

                </div>
            </div>
            <div className="bottom-donate">
                <h6>The beauty of life does not depend on how happy you are, but on <span>how happy</span> others can be <span>because of you</span></h6>
            </div>
            <Footer />
        </div>
    )
}

export default Donate













// setup with razorpay payment gateway
// import React, { useEffect, useState } from "react";
// import "./donate.css"
// import { Navbar } from '../../components/navbar/Navbar'
// import { axiosRequest } from '../../services/ApiCall';
// import { SyncLoader } from "react-spinners";
// import validator from "validator";
// import { toast } from "react-toastify";
// import Footer from "../../components/footer/Footer";

// const Donate = () => {

//     const [nationality, setNationality] = useState("Indian");
//     const [amount, setAmount] = useState("");
//     const [name, setName] = useState("");
//     const [email, setEmail] = useState("");
//     const [phoneNo, setPhoneNo] = useState("");
//     const [panNo, setPanNo] = useState("");
//     const [address, setAddress] = useState("");
//     const [state, setState] = useState("");
//     const [city, setCity] = useState("");
//     const [pinCode, setPinCode] = useState("");
//     const [campaigns, setCampaigns] = useState([]);
//     const [selectedCampaign, setSelectedCampaign] = useState("");
//     const [isLoading, setIsLoading] = useState(false); // State variable to track loading state
//     const [minDonation, setMinDonation] = useState(0);

//     useEffect(() => {
//         // Fetch the campaigns from the backend
//         const fetchCampaigns = async () => {
//             try {
//                 const { data } = await axiosRequest.get("/campaign/get");
//                 setCampaigns(data.campaign);
//             } catch (error) {
//                 console.error("Error fetching campaigns:", error);
//             }
//         };

//         fetchCampaigns();
//     }, []);

//     // const handleChangeAmount = (e) => {
//     //     if (!isNaN(e.target.value)) {
//     //         setAmount(e.target.value);
//     //     }
//     // };

//     // const handleChangeCampaign = (e) => {
//     //     setSelectedCampaign(e.target.value);
//     // };

//     const handleChangeAmount = (e) => {
//         const value = e.target.value;
//         if (!isNaN(value) && value >= minDonation) {
//             setAmount(value);
//         } else if (value === "") {
//             setAmount("");
//         }
//     };

//     // const handleChangeAmount = (e) => {
//     //     const value = parseFloat(e.target.value);
//     //     if (!isNaN(value)) {
//     //         if (value >= minDonation) {
//     //             setAmount(value);
//     //         } else if (value === "") {
//     //             setAmount("");
//     //         } else {
//     //             // Optionally show an error message
//     //             toast.error(`Amount cannot be less than ${minDonation}`);
//     //         }
//     //     }
//     // };

//     const handleChangeCampaign = (e) => {
//         const selected = campaigns.find(campaign => campaign.campaignName === e.target.value);
//         setSelectedCampaign(selected.campaignName);
//         setMinDonation(selected.minDonation || 0); // Set minimum donation amount if it exists
//         setAmount(selected.minDonation || ""); // Update the amount field with minimum donation amount
//     };

//     const checkoutHandler = async () => {
//         try {
//             if (!name || !phoneNo || !email || !amount || !selectedCampaign || !address || !state || !city || !pinCode || !nationality) {
//                 toast.error("All fields are required");
//             } else if (!validator.isMobilePhone(phoneNo, 'en-IN')) {
//                 toast.error('Invalid Phone Number');
//             } else if (!validator.isEmail(email)) {
//                 toast.error("Invalid Email");
//             } else if (panNo && !validator.matches(panNo, /^[A-Z]{5}[0-9]{4}[A-Z]$/)) {
//                 toast.error("Invalid PAN Number");
//             } else if (parseFloat(amount) < minDonation) {
//                 toast.error(`Amount cannot be less than ${minDonation}`);
//             } else {

//                 setIsLoading(true); // Set loading state to true
//                 // Fetch Razorpay API key from backend
//                 const { data: { key } } = await axiosRequest.get("/getkey");

//                 // Send request to create order along with amount and Razorpay API key
//                 const { data: { order } } = await axiosRequest.post("/checkout", {
//                     amount,
//                     razorpayKey: key, // Include the received Razorpay API key
//                 });

//                 // Construct options for Razorpay
//                 const options = {
//                     key,
//                     amount: order.amount,
//                     currency: "INR",
//                     name: "Kishori Priya Foundation",
//                     description: "Donation",
//                     image: "/assets/banner-logo.png",
//                     order_id: order.id,
//                     // callback_url: "http://localhost:8080/api/v1/paymentverification",
//                     // callback_url: `http://localhost:8080/api/v1/paymentverification?name=${name}&email=${email}&phoneNo=${phoneNo}&amount=${amount}&panNo=${panNo}&campaign=${selectedCampaign}&address=${address}&state=${state}&city=${city}&pinCode=${pinCode}&nationality=${nationality}`,
//                     callback_url: `https://backendbackup-qw5ft.ondigitalocean.app/api/v1/paymentverification?name=${name}&email=${email}&phoneNo=${phoneNo}&amount=${amount}&panNo=${panNo}&campaign=${selectedCampaign}&address=${address}&state=${state}&city=${city}&pinCode=${pinCode}&nationality=${nationality}`,
//                     prefill: {
//                         name,
//                         email,
//                         contact: phoneNo,
//                     },
//                     notes: {
//                         "address": "Razorpay Corporate Office"
//                     },
//                     theme: {
//                         "color": "#121212"
//                     }
//                 };
//                 const razor = new window.Razorpay(options);
//                 razor.open();
//             }
//         } catch (error) {
//             console.error("Error during Razorpay checkout:", error);
//         } finally {
//             setIsLoading(false); // Set loading state to false after API call is complete
//         }
//     };

//     // Render loading indicator when isLoading is true
//     if (isLoading) {
//         return (
//             <div style={{
//                 display: 'flex',
//                 justifyContent: 'center',
//                 alignItems: 'center',
//                 height: '100vh',
//                 backgroundColor: "#F6F6F6"
//             }}>
//                 <SyncLoader color="#4496D2" />
//             </div>
//         );
//     }

//     return (
//         <div className='donate'>
//             <Navbar />
//             <div className='donate-header'>
//                 <h1>Donate Now</h1>
//                 <h5>Home &gt; <span>Donate</span></h5>
//             </div>

//             <div className="donate-cont">
//                 <div className="donate-card">
//                     <h2>Yes I’d like to help</h2>
//                     <label htmlFor="nationality" className="donate-field-label">
//                         Nationality
//                         <div>
//                             <input
//                                 type="radio"
//                                 name="nationality"
//                                 id="indian"
//                                 value="Indian"
//                                 checked={nationality === "Indian"}
//                                 onChange={(e) => setNationality(e.target.value)}
//                             />
//                             Indian
//                             <input
//                                 type="radio"
//                                 name="nationality"
//                                 id="nri"
//                                 value="NRI"
//                                 checked={nationality === "NRI"}
//                                 onChange={(e) => setNationality(e.target.value)}
//                             />
//                             Foreign Citizen
//                         </div>
//                     </label>
//                     <label htmlFor="campaign" className="donate-field-label">Campaign
//                         <select
//                             value={selectedCampaign}
//                             onChange={handleChangeCampaign}
//                         >
//                             <option value="" disabled>Select a campaign</option>
//                             {campaigns?.map((campaign) => (
//                                 <option key={campaign._id} value={campaign.campaignName}>
//                                     {campaign?.campaignName}
//                                 </option>
//                             ))}
//                         </select>
//                     </label>

//                     <div className="donate-fields">
//                         Details
//                         <div className="donate-input-fields">
//                             <label htmlFor="name">Name
//                                 <input
//                                     type="text"
//                                     id="name"
//                                     value={name}
//                                     placeholder="Enter your name"
//                                     onChange={(e) => setName(e.target.value)}
//                                 />
//                             </label>
//                             <label htmlFor="email">Email
//                                 <input
//                                     type="email"
//                                     id="email"
//                                     value={email}
//                                     placeholder="Enter your email"
//                                     onChange={(e) => setEmail(e.target.value)}
//                                 />
//                             </label>
//                             <label htmlFor="phoneNo">Phone number
//                                 <input
//                                     type="tel"
//                                     id="phoneNo"
//                                     value={phoneNo}
//                                     placeholder="Enter your phone number"
//                                     onChange={(e) => setPhoneNo(e.target.value)}
//                                 />
//                             </label>
//                             <label htmlFor="panNo">Pan no (optional)
//                                 <input
//                                     type="text"
//                                     id="panNo"
//                                     value={panNo}
//                                     placeholder="Enter your PAN number"
//                                     onChange={(e) => setPanNo(e.target.value)}
//                                 />
//                             </label>
//                             <label htmlFor="address">Address
//                                 <input
//                                     type="text"
//                                     id="address"
//                                     value={address}
//                                     placeholder="Enter the address"
//                                     onChange={(e) => setAddress(e.target.value)}
//                                 />
//                             </label>
//                             <label htmlFor="state">State
//                                 <input
//                                     type="text"
//                                     id="state"
//                                     value={state}
//                                     placeholder="Enter the state"
//                                     onChange={(e) => setState(e.target.value)}
//                                 />
//                             </label>
//                             <label htmlFor="city">City
//                                 <input
//                                     type="text"
//                                     id="city"
//                                     value={city}
//                                     placeholder="Enter the city"
//                                     onChange={(e) => setCity(e.target.value)}
//                                 />
//                             </label>
//                             <label htmlFor="pin code">Pin Code
//                                 <input
//                                     type="text"
//                                     id="pinCode"
//                                     value={pinCode}
//                                     placeholder="Enter the pin code"
//                                     onChange={(e) => setPinCode(e.target.value)}
//                                 />
//                             </label>
//                         </div>

//                     </div>
//                     <label htmlFor="amount" className="donate-amount-label">Amount
//                         <input
//                             type="text"
//                             id="amount"
//                             value={amount}
//                             placeholder="Enter the amount"
//                             // placeholder={`Enter the amount (min ${minDonation})`}
//                             onChange={handleChangeAmount}
//                             min={minDonation}
//                         />
//                     </label>
//                     <button className="donate-btn" onClick={checkoutHandler}>
//                         Donate
//                     </button>
//                 </div>
//             </div>
//             <div className="bottom-donate">
//                 <h6>The beauty of life does not depend on how happy you are, but on <span>how happy</span> others can be <span>because of you</span></h6>
//             </div>
//             <Footer />
//         </div>
//     )
// }

// export default Donate