import React, { useEffect, useState } from "react";
import "./home.css";
import { Navbar } from "../../components/navbar/Navbar";
import Banner from "../../components/banner/Banner";
import About from "../../components/about/About";
import JoinUs from "../../components/joinUs/JoinUs";
import Campigns from "../../components/campigns/Campigns";
import Footer from "../../components/footer/Footer";
import Explore from "../../components/explore/Explore";
import Services from "../../components/services/Services";
import News from "../../components/news/News";
import Programs from "../../components/programs/Programs";
import Support from "../../components/support/Support";
import Contact from "../../components/contact/Contact";
import { axiosRequest } from '../../services/ApiCall';

export const Home = () => {

  const [campaigns, setCampaigns] = useState([]);

  useEffect(() => {
    // Fetch the campaigns from the backend
    const fetchCampaigns = async () => {
      try {
        const { data } = await axiosRequest.get("/campaign/get");
        setCampaigns(data.campaign);
      } catch (error) {
        console.error("Error fetching campaigns:", error);
      }
    };

    fetchCampaigns();
  }, []);

  return (
    <div className="home">
      <Navbar />
      <Banner />
      <Campigns campaigns={campaigns} />
      <About />
      <JoinUs />
      <Explore />
      <Programs campaigns={campaigns} />
      <Services />
      <News />
      <Contact />
      <Support />
      <Footer />
    </div>
  );
};






// import React, { useEffect, useState, lazy, Suspense } from "react";
// import "./home.css";
// import { Navbar } from "../../components/navbar/Navbar";
// import Banner from "../../components/banner/Banner";
// import About from "../../components/about/About";
// import { axiosRequest } from '../../services/ApiCall';

// // Lazy Load Components
// // const About = lazy(() => import("../../components/about/About"));
// const JoinUs = lazy(() => import("../../components/joinUs/JoinUs"));
// const Campigns = lazy(() => import("../../components/campigns/Campigns"));
// const Services = lazy(() => import("../../components/services/Services"));
// const News = lazy(() => import("../../components/news/News"));
// const Programs = lazy(() => import("../../components/programs/Programs"));
// const Support = lazy(() => import("../../components/support/Support"));
// const Contact = lazy(() => import("../../components/contact/Contact"));
// const Footer = lazy(() => import("../../components/footer/Footer"));

// export const Home = () => {
//     const [campaigns, setCampaigns] = useState([]);
//     const [isVisible, setIsVisible] = useState(false);

//     useEffect(() => {
//         // Fetch the campaigns from the backend
//         const fetchCampaigns = async () => {
//             try {
//                 const { data } = await axiosRequest.get("/campaign/get");
//                 setCampaigns(data.campaign);
//             } catch (error) {
//                 console.error("Error fetching campaigns:", error);
//             }
//         };
//         fetchCampaigns();

//         // Lazy Load Remaining Components on Scroll
//         const handleScroll = () => {
//             if (window.scrollY > 300) {  // When user scrolls 300px, load components
//                 setIsVisible(true);
//                 window.removeEventListener("scroll", handleScroll); // Remove listener after first trigger
//             }
//         };
//         window.addEventListener("scroll", handleScroll);
//         return () => window.removeEventListener("scroll", handleScroll);
//     }, []);

//     return (
//         <div className="home">
//             <Navbar />
//             <Banner />
//             <About />

//             {/* Lazy Loaded Components */}
//             <Suspense fallback={<div>Loading...</div>}>
//                 {isVisible && (
//                     <>
//                         <JoinUs />
//                         <Campigns campaigns={campaigns} />
//                         <Programs campaigns={campaigns} />
//                         <Services />
//                         <News />
//                         <Contact />
//                         <Support />
//                         <Footer />
//                     </>
//                 )}
//             </Suspense>
//         </div>
//     );
// };







// public html file code with seo optimization
{/* <head>
  <meta charset="utf-8" />
  <link rel="icon" href="%PUBLIC_URL%/assets/banner-logo.webp" type="image/webp" />
  <meta name="viewport" content="width=device-width, initial-scale=1" />
  <meta name="theme-color" content="#000000" />
  <meta name="description"
    content="Shree Kishori Priya Foundation: An effort to support elderly women with care, compassion, and empowerment through healthcare, wellness, daily needs, and food assistance." />
  <link rel="apple-touch-icon" href="%PUBLIC_URL%/assets/banner-logo.webp" />

  <!-- ✅ Preload Logo for Faster Loading -->
  <link rel="preload" as="image" href="%PUBLIC_URL%/assets/logo.webp" type="image/webp">

  <!-- SEO Optimizations -->
  <meta property="og:title" content="Shree Kishori Priya Foundation">
  <meta property="og:description" content="Supporting elderly women with care, compassion, and empowerment.">
  <meta property="og:image" content="%PUBLIC_URL%/assets/banner-logo.webp">
  <meta property="og:url" content="https://yourwebsite.com">
  <meta property="og:type" content="website">
  <meta name="twitter:card" content="summary_large_image">
  <meta name="twitter:title" content="Shree Kishori Priya Foundation">
  <meta name="twitter:description" content="Supporting elderly women with care, compassion, and empowerment.">
  <meta name="twitter:image" content="%PUBLIC_URL%/assets/banner-logo.webp">
  <link rel="canonical" href="https://yourwebsite.com">

  <!-- Schema Markup -->
  <script type="application/ld+json">
  {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Shree Kishori Priya Foundation",
    "url": "https://yourwebsite.com",
    "logo": "%PUBLIC_URL%/assets/banner-logo.webp",
    "description": "Supporting elderly women with care, compassion, and empowerment.",
    "sameAs": [
      "https://www.facebook.com/yourpage",
      "https://twitter.com/yourpage",
      "https://www.instagram.com/yourpage"
    ]
  }
  </script>

  <!-- Performance Optimizations -->
  <link rel="dns-prefetch" href="//fonts.googleapis.com">
  <link rel="dns-prefetch" href="//checkout.razorpay.com">
  <link rel="dns-prefetch" href="//sdk.cashfree.com">
  <link rel="prefetch" href="https://checkout.razorpay.com/v1/checkout.js">
  <link rel="prefetch" href="https://sdk.cashfree.com/checkout.js">

  <!-- Preconnect -->
  <link rel="preconnect" href="https://fonts.googleapis.com" crossorigin>
  <link rel="preconnect" href="https://checkout.razorpay.com" crossorigin>

  <!-- Preload Fonts -->
  <link rel="preload" href="https://fonts.googleapis.com/css2?family=Nunito:wght@100;300;400;600;700;900&display=swap"
    as="style" onload="this.onload=null;this.rel='stylesheet'">
  <link rel="preload" href="https://fonts.googleapis.com/css2?family=Schoolbell&display=swap" as="style"
    onload="this.onload=null;this.rel='stylesheet'">

  <title>Shree Kishori Priya Foundation</title>
</head> */}
