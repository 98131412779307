import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios"; // Ensure you have axios installed

const API_URL = "http://localhost:8080/api/v1/"; // Replace with your actual API endpoint

// **Async Thunks for API Calls**

// Fetch all posters
export const fetchPosters = createAsyncThunk("poster/get", async (_, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${API_URL}/poster/get`);
        return response.data; // Assuming API returns an array of posters
    } catch (error) {
        return rejectWithValue(error.response?.data || "Something went wrong");
    }
});

// Add a new poster
export const addPoster = createAsyncThunk("poster/addPoster", async (posterData, { rejectWithValue }) => {
    try {
        const response = await axios.post(API_URL, posterData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || "Failed to add poster");
    }
});

// Delete a poster
export const deletePoster = createAsyncThunk("poster/deletePoster", async (posterId, { rejectWithValue }) => {
    try {
        await axios.delete(`${API_URL}/${posterId}`);
        return posterId; // Return deleted poster ID for removal in state
    } catch (error) {
        return rejectWithValue(error.response?.data || "Failed to delete poster");
    }
});

// **Create Slice**
const posterSlice = createSlice({
    name: "poster",
    initialState: {
        posters: [],
        loading: false,
        error: null,
    },
    reducers: {}, // Add sync reducers if needed
    extraReducers: (builder) => {
        builder
            .addCase(fetchPosters.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchPosters.fulfilled, (state, action) => {
                state.loading = false;
                state.posters = action.payload;
            })
            .addCase(fetchPosters.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(addPoster.fulfilled, (state, action) => {
                state.posters.push(action.payload);
            })
            .addCase(deletePoster.fulfilled, (state, action) => {
                state.posters = state.posters.filter((poster) => poster.id !== action.payload);
            });
    },
});

// Export Reducer
export default posterSlice.reducer;
