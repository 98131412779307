import { configureStore } from '@reduxjs/toolkit'
import posterReducer from './features/poster/posterSlice';
// import userReducer from './features/user/userSlice';
// import counterReducer from "./features/counterSlice";

// export const makeStore = () => {
//   return configureStore({
//     reducer: {
//       user: userReducer, 
//       restaurant: restaurantReducer, 
//     },
//   })
// }

const store = configureStore({
  reducer: {
    poster: posterReducer, 
    // user: userReducer, 
    // counter: counterReducer, 
  },
});

export default store;
