import React from 'react'

const Profile = () => {
  return (
    <div>
      <header>
        <h1>profile</h1>
      </header>
    </div>
  )
}

export default Profile