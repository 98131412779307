import React from 'react'
import './paymentsuccess.css'
import { useNavigate, useSearchParams } from "react-router-dom"

const PaymentSuccess = () => {

    const seachQuery = useSearchParams()[0]

    const referenceNum = seachQuery.get("reference")

    const navigate = useNavigate();

    return (
        <div className='payment-success'>
            <img src="/assets/success.gif" alt="" />
            <h1>Payment Successfull</h1>
            {/* <span>
                Reference No. {referenceNum}
            </span> */}
            <button onClick={() => navigate('/')}>Go to home</button>
        </div>
    )
}

export default PaymentSuccess