import React, { useEffect, useState } from "react";
import "./poster.css"
import axios from "axios";
import { Table, TableHead, TableRow, TableCell, TableBody, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import { useSelector, useDispatch } from "react-redux";
import { fetchPosters } from "../../../lib/store/features/poster/posterSlice";
// import { fetchPosters, addPoster, deletePoster } from "../lib/features/poster/posterSlice";

const Poster = () => {
    // const [posters, setPosters] = useState([]);
    const [addPosterModalOpen, setAddPosterModalOpen] = useState(false);
    const [posterName, setPosterName] = useState("");
    const [posterImage, setPosterImage] = useState(null);


    const dispatch = useDispatch();
    const { posters, loading, error } = useSelector((state) => state.poster);

    useEffect(() => {
        dispatch(fetchPosters()); // Fetch posters when component mounts
    }, [dispatch]);

    //   const handleAddPoster = () => {
    //     const newPoster = {
    //       title: "New Poster",
    //       imageUrl: "https://via.placeholder.com/150", // Replace with actual data
    //     };
    //     dispatch(addPoster(newPoster));
    //   };

    //   const handleDeletePoster = (id) => {
    //     dispatch(deletePoster(id));
    //   };


    // useEffect(() => {
    //     fetchPosters();
    // }, []);

    // const fetchPosters = async () => {
    //     try {
    //         const response = await axios.get("http://localhost:8080/api/v1/poster/get"); // Replace with your API endpoint
    //         setPosters(response.data);
    //     } catch (error) {
    //         console.error("Error fetching posters:", error);
    //     }
    // };

    // Handle file change
    const handleFileChange = (event) => {
        setPosterImage(event.target.files[0]);
    };

    // Handle form submission
    const handleAddPoster = async () => {
        if (!posterName || !posterImage) {
            alert("Please provide both a name and an image.");
            return;
        }

        const formData = new FormData();
        formData.append("poster_name", posterName);
        formData.append("image", posterImage);

        try {
            const response = await axios.post("http://localhost:8080/api/v1/poster/add", formData, {
                headers: { "Content-Type": "multipart/form-data" },
            });

            // setPosters((prev) => [...prev, response.data]); // Update the state with new poster
            setAddPosterModalOpen(false); // Close modal
            setPosterName(""); // Reset form fields
            setPosterImage(null);
        } catch (error) {
            console.error("Error adding poster:", error);
        }
    };

    const deletePoster = async (id) => {
        try {
            await axios.delete(`http://localhost:8080/api/v1/poster/delete/${id}`); // Replace with your delete API endpoint
            // setPosters((prevPosters) => prevPosters.filter((poster) => poster.id !== id));
        } catch (error) {
            console.error("Error deleting poster:", error);
        }
    };

    return (
        <div className="poster">
            <nav className="poster-header">
                <h2>Posters</h2>
                <button className="primary-button" onClick={() => setAddPosterModalOpen(true)}>Add Poster</button>
                <Dialog
                    open={addPosterModalOpen}
                    onClose={() => setAddPosterModalOpen(false)}
                    fullWidth={true} // Set to true to make the dialog take up the full width
                    maxWidth="xs"
                    PaperProps={{ style: { backgroundColor: '#F6F6F6', maxWidth: '300px', borderRadius: '20px' } }}
                >
                    <DialogContent>
                        <div className="modal-content">
                            <label>
                                Name
                                <input
                                    type="text"
                                    value={posterName}
                                    onChange={(e) => setPosterName(e.target.value)}
                                    required
                                />
                            </label>
                            <label>
                                Image
                                <input type="file" accept="image/*" onChange={handleFileChange} required />
                            </label>
                            <button className="primary-button" onClick={handleAddPoster}>Add Poster</button>
                        </div>
                    </DialogContent>
                </Dialog>
            </nav>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Image</TableCell>
                        <TableCell>Title</TableCell>
                        <TableCell>Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {posters.map((poster) => (
                        <TableRow key={poster._id}>
                            <TableCell>{poster._id}</TableCell>
                            <TableCell>
                                {/* <img src={poster.image} alt={poster.name} width="100" height="100" /> */}
                                {/* <img src={`${process.env.POSTER_URL}/${poster.image}`} alt={poster.name} width="100" height="100" /> */}
                                {/* <img src={`${process.env.REACT_APP_POSTER_URL}/${poster.image}`} alt={poster.name} width="100" height="100" /> */}
                                <img src={`http://localhost:8080/api/v1/public/posters/${poster.image}`} alt={poster.name} width="100" height="100" />
                                {/* {console.log(`${process.env.REACT_APP_POSTER_URL}/${poster.image}`)} */}
                            </TableCell>
                            <TableCell>{poster.name}</TableCell>
                            <TableCell>
                                <IconButton onClick={() => deletePoster(poster._id)} color="error">
                                    <DeleteIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
};

export default Poster;










// import React, { useEffect } from "react";
// import { useSelector, useDispatch } from "react-redux";
// import { fetchPosters, addPoster, deletePoster } from "../redux/features/poster/posterSlice";

// const PostersList = () => {
//   const dispatch = useDispatch();
//   const { posters, loading, error } = useSelector((state) => state.poster);

//   useEffect(() => {
//     dispatch(fetchPosters()); // Fetch posters when component mounts
//   }, [dispatch]);

//   const handleAddPoster = () => {
//     const newPoster = {
//       title: "New Poster",
//       imageUrl: "https://via.placeholder.com/150", // Replace with actual data
//     };
//     dispatch(addPoster(newPoster));
//   };

//   const handleDeletePoster = (id) => {
//     dispatch(deletePoster(id));
//   };

//   return (
//     <div>
//       <h2>Posters</h2>
//       {loading && <p>Loading...</p>}
//       {error && <p style={{ color: "red" }}>{error}</p>}
//       <button onClick={handleAddPoster}>Add Poster</button>
//       <ul>
//         {posters.map((poster) => (
//           <li key={poster.id}>
//             <img src={poster.imageUrl} alt={poster.title} width="100" />
//             <p>{poster.title}</p>
//             <button onClick={() => handleDeletePoster(poster.id)}>Delete</button>
//           </li>
//         ))}
//       </ul>
//     </div>
//   );
// };

// export default PostersList;
