import React, { useState } from 'react'
import "./explore.css"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';

function Arrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "flex", // Use flex to center the icon
        alignItems: "center",
        justifyContent: "center",
        background: "#4496D2", // Change background color (e.g., orange-red)
        color: "#FFFFFF", // Change arrow color
        width: "42px", // Adjust width
        height: "42px", // Adjust height
        borderRadius: "50%", // Make it a circle
        boxShadow: "0 0 10px rgba(0,0,0,0.2)", // Add shadow
        zIndex: 10, // Ensure it's above other elements
        cursor: "pointer",
      }}
      onClick={onClick}
    />
  );
}

const LazyYouTubeEmbed = ({ videoId }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const loadVideo = () => setIsLoaded(true);

  return (
    <div className="lazy-video-wrapper" onClick={loadVideo}>
      {!isLoaded ? (
        <div className="video-thumbnail">
          <img
            src={`https://img.youtube.com/vi/${videoId}/hqdefault.jpg`}
            alt="YouTube Video"
            loading="lazy"
            width="100%"
          />
          <button className="play-button"><PlayCircleFilledWhiteOutlinedIcon sx={{ color: '#FFFFFF', fontSize: '2rem' }} /></button>
        </div>
      ) : (
        <iframe
          width="520"
          height="320"
          src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        ></iframe>
      )}
    </div>
  );
};

const Explore = () => {

  var settings = {
    // nextArrow: <Arrow className="slick-next" />,
    // prevArrow: <Arrow className="slick-prev" />,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <Arrow />,
    prevArrow: <Arrow />,
  };

  const handleExploreClick = () => {
    window.open("https://www.youtube.com/@ShreeKishoriPriyaFoundation", "_blank");
  };

  return (
    <div className='explore'>
      <div className='explore-header'>
        <h6>- Kishori Priya Foundation's YouTube Channel</h6>
        <h1>Explore Life at Kishori Priya Foundation</h1>
      </div>

      <div className='explore-cont'>
        <div className="left-explore">
          <p>Dive into the heartwarming stories and inspiring moments that make life at Kishori Priya Foundation so special. Watch our videos to get a glimpse of the love, care, and compassion that define our mission of serving the elderly.</p>
          <button onClick={handleExploreClick}>Explore Youtube</button>
        </div>

        <div className="right-explore">
          <div className="explore-slider">
            <Slider {...settings}>
              <div className="v-slide-card">
                <LazyYouTubeEmbed videoId="jl6aCmBxOVk" />
              </div>
              <div className="v-slide-card">
                <LazyYouTubeEmbed videoId="6P_wnA5MGO4" />
              </div>
            </Slider>
          </div>
        </div>

      </div>

    </div>
  )
}

export default Explore




// live
// import React from 'react'
// import "./explore.css"
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

// // function Arrow(props) {
// //     const { className, style, onClick } = props;
// //     return (
// //         <div
// //             className={className}
// //             style={{
// //                 ...style,
// //                 display: "block",
// //                 background: "black",
// //                 color: "#101010",
// //                 // width: "40px",
// //                 // height: "40px",
// //                 margin: "0",
// //                 padding: "0",
// //                 borderRadius: "50%",
// //                 boxSizing: "border-box",
// //             }}
// //             onClick={onClick}
// //         />
// //     );
// // }

// function Arrow(props) {
//     const { className, style, onClick } = props;
//     return (
//         <div
//             className={className}
//             style={{
//                 ...style,
//                 display: "flex", // Use flex to center the icon
//                 alignItems: "center",
//                 justifyContent: "center",
//                 background: "#4496D2", // Change background color (e.g., orange-red)
//                 color: "#FFFFFF", // Change arrow color
//                 width: "42px", // Adjust width
//                 height: "42px", // Adjust height
//                 borderRadius: "50%", // Make it a circle
//                 boxShadow: "0 0 10px rgba(0,0,0,0.2)", // Add shadow
//                 zIndex: 10, // Ensure it's above other elements
//                 cursor: "pointer",
//             }}
//             onClick={onClick}
//         />
//     );
// }


// const Explore = () => {

//     var settings = {
//         // nextArrow: <Arrow className="slick-next" />,
//         // prevArrow: <Arrow className="slick-prev" />,
//         dots: true,
//         infinite: true,
//         speed: 500,
//         slidesToShow: 1,
//         slidesToScroll: 1,
//         nextArrow: <Arrow />,
//         prevArrow: <Arrow />,
//     };

//     const handleExploreClick = () => {
//         window.open("https://www.youtube.com/@ShreeKishoriPriyaFoundation", "_blank");
//     };

//     return (
//         <div className='explore'>
//             <div className='explore-header'>
//                 <h6>- Kishori Priya Foundation's YouTube Channel</h6>
//                 <h1>Explore Life at Kishori Priya Foundation</h1>
//             </div>

//             <div className='explore-cont'>
//                 <div className="left-explore">
//                     <p>Dive into the heartwarming stories and inspiring moments that make life at Kishori Priya Foundation so special. Watch our videos to get a glimpse of the love, care, and compassion that define our mission of serving the elderly.</p>
//                     <button onClick={handleExploreClick}>Explore Youtube</button>
//                 </div>

//                 <div className="right-explore">
//                     <div className="explore-slider">
//                         <Slider {...settings}>
//                             <div className="v-slide-card">
//                                 <iframe
//                                     width="520"
//                                     height="320"
//                                     src="https://www.youtube.com/embed/jl6aCmBxOVk?si=gMTWxw08lTf6lsWq"
//                                     title="YouTube video player"
//                                     frameBorder="0"
//                                     allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
//                                     referrerPolicy="strict-origin-when-cross-origin"
//                                     allowFullScreen
//                                 ></iframe>
//                             </div>
//                             <div className="v-slide-card">
//                                 <iframe
//                                     width="560"
//                                     height="315"
//                                     src="https://www.youtube.com/embed/6P_wnA5MGO4?si=Kb4k2rb28I7TBfiH"
//                                     title="YouTube video player"
//                                     frameBorder="0"
//                                     allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
//                                     referrerPolicy="strict-origin-when-cross-origin"
//                                     allowFullScreen
//                                 ></iframe>
//                             </div>
//                         </Slider>
//                     </div>
//                 </div>

//             </div>

//         </div>
//     )
// }

// export default Explore







// import React, { useState } from 'react';

// const LazyYouTubeEmbed = ({ videoId }) => {
//     const [isVideoLoaded, setIsVideoLoaded] = useState(false);

//     const loadVideo = () => {
//         setIsVideoLoaded(true);
//     };

//     return (
//         <div className="lazy-youtube" onClick={loadVideo} style={{ cursor: "pointer", position: "relative" }}>
//             {!isVideoLoaded ? (
//                 <>
//                     <img
//                         src={`https://img.youtube.com/vi/${videoId}/hqdefault.jpg`}
//                         alt="YouTube Thumbnail"
//                         style={{ width: "100%", height: "auto" }}
//                     />
//                     <div className="play-button" style={{
//                         position: "absolute",
//                         top: "50%",
//                         left: "50%",
//                         transform: "translate(-50%, -50%)",
//                         background: "rgba(0, 0, 0, 0.6)",
//                         color: "#fff",
//                         padding: "10px 15px",
//                         borderRadius: "50%",
//                         fontSize: "20px"
//                     }}>
//                         ▶
//                     </div>
//                 </>
//             ) : (
//                 <iframe
//                     width="520"
//                     height="320"
//                     src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
//                     title="YouTube video player"
//                     frameBorder="0"
//                     allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
//                     referrerPolicy="strict-origin-when-cross-origin"
//                     allowFullScreen
//                 ></iframe>
//             )}
//         </div>
//     );
// };

// export default LazyYouTubeEmbed;










// import React, { useState } from "react";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import "./explore.css";

// function Arrow(props) {
//   const { className, style, onClick } = props;
//   return (
//     <div
//       className={className}
//       style={{
//         ...style,
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "center",
//         background: "#4496D2",
//         color: "#FFFFFF",
//         width: "42px",
//         height: "42px",
//         borderRadius: "50%",
//         boxShadow: "0 0 10px rgba(0,0,0,0.2)",
//         zIndex: 10,
//         cursor: "pointer",
//       }}
//       onClick={onClick}
//     />
//   );
// }

// const LazyYouTubeEmbed = ({ videoId }) => {
//   const [isLoaded, setIsLoaded] = useState(false);

//   const loadVideo = () => setIsLoaded(true);

//   return (
//     <div className="lazy-video-wrapper" onClick={loadVideo}>
//       {!isLoaded ? (
//         <div className="video-thumbnail">
//           <img
//             src={`https://img.youtube.com/vi/${videoId}/hqdefault.jpg`}
//             alt="YouTube Video"
//             loading="lazy"
//             width="100%"
//           />
//           <div className="play-button">▶</div>
//         </div>
//       ) : (
//         <iframe
//           width="520"
//           height="320"
//           src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
//           title="YouTube video player"
//           frameBorder="0"
//           allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
//           referrerPolicy="strict-origin-when-cross-origin"
//           allowFullScreen
//         ></iframe>
//       )}
//     </div>
//   );
// };

// const Explore = () => {
//   var settings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     nextArrow: <Arrow />,
//     prevArrow: <Arrow />,
//   };

//   const handleExploreClick = () => {
//     window.open("https://www.youtube.com/@ShreeKishoriPriyaFoundation", "_blank");
//   };

//   return (
//     <div className="explore">
//       <div className="explore-header">
//         <h6>- Kishori Priya Foundation's YouTube Channel</h6>
//         <h1>Explore Life at Kishori Priya Foundation</h1>
//       </div>

//       <div className="explore-cont">
//         <div className="left-explore">
//           <p>
//             Dive into the heartwarming stories and inspiring moments that make life at
//             Kishori Priya Foundation so special. Watch our videos to get a glimpse of the
//             love, care, and compassion that define our mission of serving the elderly.
//           </p>
//           <button onClick={handleExploreClick}>Explore Youtube</button>
//         </div>

//         <div className="right-explore">
//           <div className="explore-slider">
//             <Slider {...settings}>
//               <div className="v-slide-card">
//                 <LazyYouTubeEmbed videoId="jl6aCmBxOVk" />
//               </div>
//               <div className="v-slide-card">
//                 <LazyYouTubeEmbed videoId="6P_wnA5MGO4" />
//               </div>
//             </Slider>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Explore;
