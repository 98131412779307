import React from 'react'
import './refundpolicy.css';
import { Navbar } from '../../components/navbar/Navbar'
import Footer from '../../components/footer/Footer'
import { FaPhone, FaEnvelope } from 'react-icons/fa6';
// import EmailIcon from '@mui/icons-material/Email';

const RefundPolicy = () => {
    return (
        <div className="refund-policy">
            <Navbar />
            <div className='refund-policy-header'>
                <h1>Refund Policy</h1>
                <h5>Home &gt; <span>Refund Policy</span></h5>
            </div>

            <div className='refund-policy-cont'>
                <section className="refund-policy-content">
                    {/* <h2>Refund Policy</h2>
                    <p><strong>Effective Date:</strong> [Insert Date]</p> */}

                    <p>
                        At <strong>Shree Kishori Priya Foundation</strong> (<a href="https://shreekishoripriya.org" target="_blank" rel="noopener noreferrer">shreekishoripriya.org</a>),
                        we deeply appreciate your generous support in helping elderly women with food, healthcare, and festival celebrations.
                        As a non-profit organization, donations play a crucial role in fulfilling our mission.
                    </p>

                    <article>
                        <h2>1. Donations Are Non-Refundable</h2>
                        <p>
                            All donations made to <strong>Shree Kishori Priya Foundation</strong> are voluntary contributions to support our initiatives.
                            Since donations are used for charitable purposes immediately, they are <strong>non-refundable</strong>.
                        </p>
                    </article>
                    <article>
                        <h2>2. Exceptional Circumstances for Refunds</h2>
                        <p>We understand that errors may occur while making donations. Refund requests will be considered only in the following cases:</p>
                        <ul>
                            <li><strong>Duplicate Transactions:</strong> If you accidentally made multiple donations for the same cause.</li>
                            <li><strong>Incorrect Donation Amount:</strong> If you mistakenly entered an incorrect donation amount.</li>
                            <li><strong>Technical Errors:</strong> If a transaction fails due to a system glitch but your payment was deducted.</li>
                        </ul>
                    </article>
                    <article>
                        <h2>3. How to Request a Refund</h2>
                        <p>If you believe you qualify for a refund, please contact us within <strong>7 days</strong> of your donation with the following details:</p>
                        <ul>
                            <li>Full Name</li>
                            <li>Donation Receipt or Transaction ID</li>
                            <li>Date of Donation</li>
                            <li>Reason for Refund Request</li>
                        </ul>
                    </article>
                    <article>
                        <h2>Contact Information:</h2>
                        <address>
                            <p><strong>Shree Kishori Priya Foundation</strong></p>
                            {/* <p>Vrindavan, Mathura, 281121</p> */}
                            <p><FaPhone /> <a href="tel:+918979545845">+91-8979545845</a></p>
                            <p><FaEnvelope /> <a href="mailto:shreekishoripriya@gmail.com">shreekishoripriya@gmail.com</a></p>
                        </address>
                    </article>
                    <article>
                        <h2>4. Refund Processing</h2>
                        <p>
                            If approved, refunds will be processed within <strong>7-14 business days</strong> via the original payment method.
                            Please note that processing times may vary based on your bank or payment provider.
                        </p>
                    </article>
                    <article>
                        <h2>5. Changes to This Policy</h2>
                        <p>We reserve the right to update this policy at any time. Any changes will be posted on this page.</p>
                    </article>

                    <p><strong>Thank you for your support in improving the lives of elderly women.</strong></p>
                </section>
            </div>

            <div className="bottom-refund-policy">
                <h6>The beauty of life does not depend on how happy you are, but on <span>how happy</span> others can be <span>because of you</span></h6>
            </div>
            <Footer />
        </div>
    )
}

export default RefundPolicy