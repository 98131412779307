import React from 'react'
import "./programs.css"
import { ClipLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";

const Programs = ({ campaigns }) => {

    const navigate = useNavigate();

    // Find the campaign with the name 'Healthcare services and wellness programs'
    const campaignData = campaigns?.find(campaign => campaign?.campaignName === 'Healthcare services and wellness programs');

    return (
        <div className='program'>
            <div className="top-program">
                <div className="tp-items">
                    <div className="tp-item">
                        <img src="/assets/programs/Icon.svg" alt="" />
                        <h2>300+</h2>
                        <h4>Best Campaigns</h4>
                    </div>
                    <div className="tp-item">
                        <img src="/assets/programs/Icon-1.svg" alt="" />
                        <h2>1lakh+</h2>
                        <h4>Raised Funds</h4>
                    </div>
                    <div className="tp-item">
                        <img src="/assets/programs/Icon-2.svg" alt="" />
                        <h2>200+</h2>
                        <h4>Satisfied Donors</h4>
                    </div>
                    <div className="tp-item">
                        <img src="/assets/programs/Icon-3.svg" alt="" />
                        <h2>50+</h2>
                        <h4>Best Volunteers</h4>
                    </div>
                </div>
            </div>

            <div className="bottom-program">
                <div className="program-card">
                    <div className="left-program-image">
                        <img src="/assets/program.webp" alt="" />
                    </div>
                    <div className="right-program-detail">
                        <div className='program-days'>
                            <span>30 Days Left</span>
                            <span>20+ Suppoters</span>
                        </div>
                        <h1>Healthcare services and wellness programs</h1>
                        <h6>Support our seniors' health and well-being by contributing to our healthcare and wellness programs</h6>

                        <div className='program-btn-cont'>
                            <div className='prog-raised'>
                                <img src="/assets/hand.svg" alt="" />
                                {campaignData ? (
                                    <div>
                                        <span>Raised</span>
                                        <p>&#x20B9; {campaignData.raised}</p>
                                    </div>
                                ) : (
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}>
                                        <ClipLoader color="#4496D2" />
                                    </div>
                                )}
                            </div>
                            <div className='prog-raised'>
                                <img src="/assets/dollar.svg" alt="" />
                                {campaignData ? (
                                    <div>
                                        <span>Our goal</span>
                                        <p>&#x20B9; {campaignData.goal}</p>
                                    </div>
                                ) : (
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}>
                                        <ClipLoader color="#4496D2" />
                                    </div>
                                )}
                            </div>
                            <button onClick={() => navigate('/donate')}>Donate Now</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Programs