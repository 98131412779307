import React, { useEffect, useState } from 'react'
import "./usersidebar.css"
import { FaArrowLeftLong, FaBars } from 'react-icons/fa6'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { MdClose } from "react-icons/md";
import { LinearProgress } from '@mui/material';
// import { useDispatch, useSelector } from 'react-redux';
import HomeIcon from '@mui/icons-material/Home';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import HeadphonesIcon from '@mui/icons-material/Headphones';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';

const UserSidebar = () => {

    const { id } = useParams(); // Extract the ID from the URL

    const [completionPercentage, setCompletionPercentage] = useState(0);
    const [isContentVisible, setIsContentVisible] = useState(false);
    const [isBars, setIsBars] = useState(true);
    const [showMenu, setShowMenu] = useState(false);
    const [initials, setInitials] = useState('');

    // const { user } = useSelector((state) => state.HNauthReducer);

    const location = useLocation();
    // const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {
            const h3Element = document.querySelector('.krinvilogo-img-cont h3');
            if (h3Element && h3Element.innerText) {
                const h3Text = h3Element.innerText;
                const words = h3Text.split(' ');
                const firstLetters = words.map(word => {
                    const firstChar = word.charAt(0);
                    return /^[a-zA-Z0-9]/.test(firstChar) ? firstChar.toUpperCase() : '';
                }).join('');
                setInitials(firstLetters);
            }
        }, 100); // Adjust the delay time as needed
        return () => clearTimeout(timer);
    }, []);

    // useEffect(() => {
    //     if (user && user?.user) {
    //         const profileFields = Object.keys(user?.user);
    //         const filledFields = profileFields.filter(field => user?.user[field]);
    //         const percentage = (filledFields.length / profileFields.length) * 100;
    //         setCompletionPercentage(percentage);
    //     }
    // }, [user]);

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    const toggleSideNav = () => {
        // toggleMenu(); // Toggle the side navigation
        setIsBars(!isBars); // Toggle the FaBars/MdClose icon
    };

    // const handleLogout = () => {
    //     dispatch(CompanyLogoutRequest());
    //     navigate('/');
    // };

    // Check if the company name is defined before accessing it
    // const companyName = user?.user?.companyname || '';

    return (
        <div className={`hdprofile ${isContentVisible ? 'show-sidebar' : ''}`}>
            {/* <div className='hdprofile'> */}
            <div className="hdpro-cont">
                <div className={`left-hdpro ${isContentVisible ? 'show-sidebar' : ''}`}>
                    {/* <div className="left-hdpro"> */}
                    <div className="left-hdpro-cont">
                        <div className="top-hleft-card">
                            <Link to="/" className='hleftcard-link'>
                                {/* <button className='hdpro-back-btn'><FaArrowLeftLong /></button> */}
                                    <div className="hdpro-logo-img-cont">
                                        <img src="/assets/logo.png" alt="" />
                                    </div>
                            </Link>
                            {/* <button className='hlc-bar-btn' onClick={handleFabarClick}><FaBars /></button> */}

                            <div className="navbar-toggle" onClick={toggleMenu} >
                                <button
                                    onClick={toggleSideNav}
                                    className='hlc-bar-btn'
                                >
                                    {isBars ? (
                                        <FaBars />
                                    ) : (
                                        <MdClose />
                                    )}
                                </button>
                            </div>
                        </div>

                        <div className={`items ${showMenu ? 'open' : ''}`} >
                            {/* {isContentVisible && ( */}
                            <div className='bottom-sidnav-hlc'>

                                <div className="krinvilogo-img-cont">
                                    {/* <img src="/assets/kt.png" alt="Krinvi" /> */}
                                    <img src="/assets/logo.png" alt="" />
                                    {/* <div className="hlc-logo">
                                        <div>{initials}</div>
                                    </div> */}
                                    {/* <h3>{companyName}</h3> */}
                                </div>

                                <div className="hlc-mid-bottom-combine">

                                    <div className="hlc-mid-cont">

                                        <Link to="/dashboard" className='hleftcard-link'>
                                            <button
                                                className={`hdpro-text-list ${location.pathname === '/dashboard' ? 'active' : ''
                                                    }`}
                                            >
                                                <HomeIcon />  Dashboard
                                            </button>
                                        </Link>

                                        <Link to="/profile" className='hleftcard-link'>
                                            <button
                                                className={`hdpro-text-list ${location.pathname === '/profile' || location.pathname === '/editprofile' ? 'active' : ''
                                                    }`}
                                            >
                                                <AccountCircleIcon /> My Profile
                                            </button>
                                        </Link>
                                        <Link to="/donations" className='hleftcard-link'>
                                            <button
                                                className={`hdpro-text-list ${location.pathname === '/donations' ? 'active' : ''
                                                    }`}
                                            >
                                                <SentimentSatisfiedAltIcon /> My Donations
                                            </button>
                                        </Link>
                                        <Link to="/donate" className='hleftcard-link'>
                                            <button
                                                className={`hdpro-text-list ${location.pathname === '/donate' ? 'active' : ''
                                                    }`}
                                            >
                                                <VolunteerActivismIcon />  Donate now
                                            </button>
                                        </Link>
                                        <Link to="/help" className='hleftcard-link'>
                                            <button
                                                className={`hdpro-text-list ${location.pathname === '/help' ? 'active' : ''
                                                    }`}
                                            >
                                                <HeadphonesIcon /> Feedback & Complaints
                                            </button>
                                        </Link>
                                        <Link to="/settings" className='hleftcard-link'>
                                            <button
                                                className={`hdpro-text-list ${location.pathname === '/settings' ? 'active' : ''
                                                    }`}
                                            >
                                                <SettingsIcon /> Settings
                                            </button>
                                        </Link>
                                        <Link to="/sign-out" className='hleftcard-link'>
                                            <button
                                                className={`hdpro-text-list ${location.pathname === '/sign-out' ? 'active' : ''
                                                    }`}
                                            >
                                                <LogoutIcon /> Sign out
                                            </button>
                                        </Link>
                                    </div>
                                </div>

                            </div>
                            {/* )} */}
                        </div>
                    </div>
                    {/* </div> */}
                </div>
            </div>
        </div>
    )
}

export default UserSidebar