// import "./layout.css";
import { Outlet } from 'react-router-dom';
import AdminSidebar from "../../../components/adminSidebar/AdminSidebar";

const AdminLayout = () => {
    return (
        <div className="sidebar-layout">
            {/* Sidebar (Fixed) */}
            <aside className="sl-sidebar">
                <AdminSidebar />
            </aside>

            {/* Main Content (Dynamic) */}
            <main className="sl-outlet">
                <Outlet /> {/* This will render the selected page */}
            </main>
        </div>
    );
};

export default AdminLayout;
