import "./layout.css";
import { Outlet } from 'react-router-dom';
import UserSidebar from '../../components/userSidebar/UserSidebar';

const SidebarLayout = () => {
  return (
    <div className="sidebar-layout">
      {/* Sidebar (Fixed) */}
      <aside className="sl-sidebar">
        <UserSidebar />
      </aside>

      {/* Main Content (Dynamic) */}
      <main className="sl-outlet">
        <Outlet /> {/* This will render the selected page */}
      </main>
    </div>
  );
};

export default SidebarLayout;
