import React, { useState, useEffect } from 'react';
import "./campigns.css";
import { LinearProgress, Skeleton } from '@mui/material';
import { ClipLoader } from "react-spinners";
import { Link } from 'react-router-dom';

const Campigns = ({ campaigns }) => {
    const [activeTab, setActiveTab] = useState('FOR OUR NGO');
    const [loading, setLoading] = useState(true);
    const [imageLoading, setImageLoading] = useState({});

    useEffect(() => {
        if (campaigns && campaigns.length > 0) {
            setLoading(false);
        }
    }, [campaigns]);

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const normalizeText = (text) => text.toLowerCase();

    const filteredCampaigns = campaigns?.filter(campaign => normalizeText(campaign?.campaignArea) === normalizeText(activeTab));

    const handleImageLoad = (id) => {
        setImageLoading(prev => ({ ...prev, [id]: false }));
    };

    return (
        <div className='campigns' id="campaigns">
            <div className='campigns-header'>
                <h6>- Donate Now</h6>
                <h1>Our Current Programs</h1>
            </div>

            <div className='campigns-tab-cont'>
                <button
                    className={`campigns-tab-btn ${normalizeText(activeTab) === 'for our ngo' ? 'active' : ''}`}
                    onClick={() => handleTabClick('FOR OUR NGO')}
                >
                    FOR OUR NGO
                </button>
                <button
                    className={`campigns-tab-btn ${normalizeText(activeTab) === 'festival' ? 'active' : ''}`}
                    onClick={() => handleTabClick('FESTIVAL')}
                >
                    FESTIVAL
                </button>
                <button
                    className={`campigns-tab-btn ${normalizeText(activeTab) === 'empower women' ? 'active' : ''}`}
                    onClick={() => handleTabClick('EMPOWER WOMEN')}
                >
                    EMPOWER WOMEN
                </button>
            </div>

            <div className='campigns-cards-cont'>
                {loading ? (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '80vh',
                    }}>
                        <ClipLoader color="#4496D2" />
                    </div>
                ) : (
                    filteredCampaigns?.map(campaign => {
                        const completionPercentage = (campaign.raised / campaign.goal) * 100;

                        return (
                            <div className="campigns-card" key={campaign._id}>
                                <div className='campign-card-img'>
                                    {/* <img src={campaign.image} alt="" /> */}
                                    {/* <img src={`/assets/campaigns/${campaign?.campaignName}.webp`} alt={campaign?.campaignName} /> */}
                                    {imageLoading[campaign._id] !== false && (
                                        <Skeleton variant="rectangular" width="100%" height={160} />
                                    )}
                                    <img
                                        src={`/assets/campaigns/${campaign?.campaignName}.webp`}
                                        alt={campaign?.campaignName}
                                        style={{ display: imageLoading[campaign._id] ? 'none' : 'block' }}
                                        onLoad={() => handleImageLoad(campaign._id)}
                                    />
                                    <div className='curve-image'>
                                        <img src="/assets/curve.svg" alt="" />
                                    </div>
                                </div>
                                <div className='campign-card-bottom'>
                                    {/* <div className='curved-shape'> */}
                                    <div className='campign-card-headline'>
                                        <h5>{campaign.campaignName}</h5>
                                    </div>
                                    <div className='campign-card-info'>
                                        <span>{campaign.description}</span>
                                    </div>
                                    <div className='hlc-profile-progress'>
                                        <LinearProgress
                                            variant="determinate"
                                            value={completionPercentage}
                                            aria-label={`Campaign progress: ${completionPercentage}%`}
                                            sx={{
                                                height: 4, // Set the height of the progress bar
                                                borderRadius: 5, // Set the border radius
                                                bgcolor: '#DDDDDD', // Set the background color
                                                '& .MuiLinearProgress-bar': {
                                                    bgcolor: '#4496D2', // Set the progress bar color
                                                },
                                            }}
                                        />
                                        <div className='campign-card-price-cont'>
                                            <div className='campign-card-price'>
                                                <span>Raised:</span>
                                                <p>&#x20B9; {campaign.raised}</p>
                                            </div>
                                            <div className='campign-card-price'>
                                                <span>Goal:</span>
                                                <p>&#x20B9; {campaign.goal}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='campign-card-btn'>
                                        <Link to='/donate'>
                                            <button>Donate Now</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        );
                    })
                )}
            </div>
        </div>
    );
};

export default Campigns;
