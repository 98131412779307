import "./dashboard.css";
import React, { useState } from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EditIcon from '@mui/icons-material/Edit';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import { LinearProgress } from '@mui/material';

const Dashboard = () => {
  const [completionPercentage, setCompletionPercentage] = useState(50);
  return (
    <div className="dashboard">
      <header className="dashboard-header">
        <div className="left-dh">
          <h1>Dashboard</h1>
          <h4>Welcome to KishoriPriya Foundation</h4>
        </div>
        <div className="right-dh">
          <AccountCircleIcon style={{ fontSize: '2rem', color: '#242020', fontWeight: '400' }} />
          <div>
            <span>Mr. Satish Wagle</span>
            <span>RRHHBB547568789</span>
          </div>
        </div>
      </header>
      <main>
        <div className="dash-cards-section">
          {/* Profile Section */}
          <section>
            <article className="dash-profile-card">
              <header className="dpc-header">
                <h2><EditIcon style={{ fontSize: '1rem' }} />Profile</h2>
                <button className="btn-primary">Update Now</button>
              </header>

              <div className="dpc-details">
                <AccountCircleIcon style={{ fontSize: '2rem' }} />
                <div>
                  <span>Mr. Satish Wagle</span>
                  <span>RRHHBB547568789</span>
                </div>
              </div>

              <div className='dpc-progress'>
                <LinearProgress
                  variant="determinate"
                  value={completionPercentage}
                  aria-label={`Campaign progress: ${completionPercentage}%`}
                  sx={{
                    height: 5, // Set the height of the progress bar
                    borderRadius: 5, // Set the border radius
                    bgcolor: '#DDDDDD', // Set the background color
                    '& .MuiLinearProgress-bar': {
                      bgcolor: '#3A98D0', // Set the progress bar color
                    },
                  }}
                />
                <div>
                  <span>{completionPercentage.toFixed(0)}%</span>
                  <span>100%</span>
                </div>
              </div>


            </article>
          </section>

          {/* Donations Section */}
          <section>
            <article className="dash-my-donation-card">
              <header className="ddc-header">
                <h2><TextSnippetOutlinedIcon style={{ fontSize: '1rem' }} />My Donations</h2>
                <button>View Receipts</button>
              </header>
              <div className="ddc-my-donation-detail">
                <span>5000/-</span>
                <img src="/assets/donation.webp" alt="" />
              </div>
              <button className="ddc-donate-now-btn">Donate Now</button>
            </article>
          </section>

          {/* Yearly Donations Section */}
          <section>
            <article className="dash-yearly-donation-card">
              <header className="ddc-header">
                <h2><EditIcon style={{ fontSize: '1rem' }} />Yearly Donations</h2>
                <button>View Receipts</button>
              </header>

              <div className="dyc-details">
                <AccountCircleIcon style={{ fontSize: '2rem', color: '#FFFFFF' }} />
                <div>
                  <span>Mr. Satish Wagle</span>
                  <span>RRHHBB547568789</span>
                </div>
              </div>

              <div className='dyc-progress'>
                <LinearProgress
                  variant="determinate"
                  value={completionPercentage}
                  aria-label={`Campaign progress: ${completionPercentage}%`}
                  sx={{
                    height: 5, // Set the height of the progress bar
                    borderRadius: 5, // Set the border radius
                    bgcolor: '#DDDDDD', // Set the background color
                    '& .MuiLinearProgress-bar': {
                      bgcolor: '#114274', // Set the progress bar color
                    },
                  }}
                />
                <div>
                  <span>{completionPercentage.toFixed(0)}%</span>
                  <span>100%</span>
                </div>
              </div>
            </article>
          </section>
        </div>

        <section className="dashboard-our-campaigns">
          <h2>Our Campaigns</h2>
          <div>
            <img src="/assets/dashboard/campaign.webp" alt="" />
          </div>
        </section>
      </main>
    </div>
  )
}

export default Dashboard