import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import SidebarLayout from './SidebarLayout';
// import Help from './pages/Help';
import { Home } from "./pages/home/Home";
import BlogDetail from './pages/blogDetail/BlogDetail';
import Blog from './pages/blog/Blog';
import PaymentSuccess from './pages/paymentSuccess/PaymentSuccess';
import Donate from './pages/donate/Donate';
import Profile from './pages/profile/Profile';
import UserSidebar from './components/userSidebar/UserSidebar';
import Dashboard from './pages/dashboard/Dashboard';
import SidebarLayout from './pages/layout/Layout';
import TermsAndConditions from './pages/terms-and-conditions/TermsAndConditions';
import PrivacyPolicy from './pages/privacy-policy/PrivacyPolicy';
import RefundPolicy from './pages/refund-policy/RefundPolicy';
import AdminLayout from './pages/admin/adminLayout/AdminLayout';
import Poster from './pages/admin/poster/Poster';

const App = () => {
  return (
    <div className="App">
      <Router>
        <Routes>
          {/* Routes with Sidebar */}
          {/* <Route element={<UserSidebar />}> */}
          <Route element={<SidebarLayout />}>
            <Route path="/profile" element={<Profile />} />
            <Route path="/dashboard" element={<Dashboard />} />
            {/* <Route path="/help" element={<Help />} /> */}
          </Route>

          <Route element={<AdminLayout />}>
            <Route path="/poster" element={<Poster />} />
          </Route>

          {/* Routes without Sidebar */}
          <Route path='/' element={<Home />} />
          <Route path='/blog' element={<Blog />} />
          <Route path='/blog/details' element={<BlogDetail />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/refund-policy' element={<RefundPolicy />} />
          <Route path='/terms-and-conditions' element={<TermsAndConditions />} />
          <Route path="/donate" element={<Donate />} />
          <Route path="/payment_success" element={<PaymentSuccess />} />
        </Routes>
      </Router>
      <ToastContainer />
    </div>
  );
};

export default App;


// import './App.css';
// import { BrowserRouter, Routes, Route } from "react-router-dom";
// import 'react-toastify/dist/ReactToastify.css';
// import { ToastContainer } from 'react-toastify';
// import { Home } from "./pages/home/Home";
// import BlogDetail from './pages/blogDetail/BlogDetail';
// import Blog from './pages/blog/Blog';
// import PaymentSuccess from './pages/paymentSuccess/PaymentSuccess';
// import Donate from './pages/donate/Donate';

// function App() {
//   return (
//     <div className="App">
//       <BrowserRouter>
//         <Routes>
//           <Route path='/' element={<Home />} />
//           <Route path='/blog' element={<Blog />} />
//           <Route path='/blog/details' element={<BlogDetail />} />
//           <Route path="/donate" element={<Donate />} />
//           <Route path="/payment_success" element={<PaymentSuccess />} />
//         </Routes>
//       </BrowserRouter>
//       <ToastContainer />
//     </div>
//   );
// }

// export default App;